import { BasicComponentProps } from '@whoop/web-components';
import React, { ReactNode } from 'react';
import Error from '../Error';
import LinkOut from '../LinkOut';
import styles from './index.module.scss';

interface ErrorBoundaryProps {
  fallback: ReactNode;
  children: ReactNode;
}

interface ErrorBoundaryState {
  error: any;
}

/**
 * A simple error boundary component that renders a fallback UI
 * when an error occurs in rendering. This ensures that the
 * rest of the rendered application remains usable.
 *
 * Note that errors that occur outside of the rendering (like
 * in an onClick action) will not trigger the fallback UI to
 * be rendered.
 *
 * https://reactjs.org/docs/error-boundaries.html
 */
export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: any) {
    return { error };
  }

  render() {
    const { error } = this.state;
    const { fallback, children } = this.props;
    return error ? fallback : children;
  }
}

export function DefaultErrorBoundary({ children }: BasicComponentProps) {
  return (
    <ErrorBoundary
      fallback={
        <div className={styles.root}>
          <Error>Something went wrong.</Error>
          <p>
            We ran into an unexpected error. Please submit a{' '}
            <LinkOut href="https://whoopinc.typeform.com/to/XmzituEp">
              request
            </LinkOut>{' '}
            to help get this resolved.
          </p>
        </div>
      }
    >
      {children}
    </ErrorBoundary>
  );
}
