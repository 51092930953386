declare global {
  interface Window {
    _env: any;
  }
}

export default {
  env: window._env?.REACT_APP_ENVIRONMENT,
  forgotPasswordUrl: window._env?.REACT_APP_FORGOT_PASSWORD_URL,
  authBaseUrl: window._env?.REACT_APP_AUTH_BASE_URL,
  apiBaseUrl: window._env?.REACT_APP_API_BASE_URL,
  apiTermsOfUseUrl: 'https://developer.whoop.com/api-terms-of-use/',
  whoopPrivacyPolicyUrl: 'https://www.whoop.com/privacy/full-privacy-policy/',
  datadogApplicationId: '0d73e042-e40e-4306-a776-7f47b4d52d62',
  datadogClientToken: 'pubfe8ccfa6371d37d49a1afed35fc28e95',
  datadogService: 'external-developer-portal',
  appApprovalSubmissionUrl: 'https://whoopinc.typeform.com/to/XmzituEp',
  appApprovalLearnMoreUrl:
    'https://developer.whoop.com/docs/developing/app-approval/',
  docs: {
    developerPlatformOverview: 'https://developer.whoop.com/docs/introduction',
    oauthRedirects:
      'https://developer.whoop.com/docs/developing/oauth#redirect-url',
    oauthScopes: 'https://developer.whoop.com/docs/developing/oauth#scope',
    webhooks: 'https://developer.whoop.com/docs/developing/webhooks',
  },
};
