import ConfirmationPrompt from 'common/components/ConfirmationPrompt';
import DefaultPageLoader from 'common/components/DefaultPageLoader';
import { UnexpectedError } from 'common/errors';
import AuthenticatedLayout from 'common/layouts/AuthenticatedLayout';
import teamService from 'common/services/team';
import { CannotLeaveTeamAsLastAdminError } from 'common/types/team';
import React from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';

function LeaveTeam() {
  const getTeamTask = useAsync(teamService.getTeam, []);

  const leaveTeamTask = useAsyncCallback(async () => {
    try {
      await teamService.leaveTeam();
      window.location.href = '/';
    } catch (e) {
      if (e instanceof CannotLeaveTeamAsLastAdminError) {
        throw new Error(
          'You cannot leave your team as the only remaining Admin member. Please submit a request via https://whoopinc.typeform.com/to/XmzituEp to help get this resolved.',
        );
      }
      throw new UnexpectedError();
    }
  });

  const team = getTeamTask.result;

  return (
    <>
      {getTeamTask.loading && <DefaultPageLoader />}
      {team && (
        <ConfirmationPrompt
          title={`Are you sure you want to leave ${team.name}?`}
          confirmationPhrase="leave team"
          confirmButtonText="Confirm"
          loading={getTeamTask.loading || leaveTeamTask.loading}
          error={getTeamTask.error?.message || leaveTeamTask.error?.message}
          onConfirm={leaveTeamTask.execute}
        />
      )}
    </>
  );
}

export default () => (
  <AuthenticatedLayout pageIdentifier="leave-team">
    <LeaveTeam />
  </AuthenticatedLayout>
);
