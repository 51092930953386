import Button from 'common/components/Button';
import DefaultPageLoader from 'common/components/DefaultPageLoader';
import Error from 'common/components/Error';
import List from 'common/components/List';
import PageHeader from 'common/components/PageHeader';
import AuthenticatedLayout from 'common/layouts/AuthenticatedLayout';
import appService from 'common/services/apps';
import React from 'react';
import { useAsync } from 'react-async-hook';
import { Link } from 'react-router-dom';
import AppListItem from './AppListItem';
import CreateAppPrompt from './CreateAppPrompt';

function Apps() {
  const getAppsTask = useAsync(appService.getApps, []);
  const apps = getAppsTask.result;
  return (
    <>
      {getAppsTask.error && <Error>{getAppsTask.error.message}</Error>}
      {getAppsTask.loading && <DefaultPageLoader />}
      {apps?.length === 0 && <CreateAppPrompt />}
      {apps && apps.length > 0 && (
        <div>
          <PageHeader
            title="Your apps"
            actions={
              <div>
                <Link to="/apps/create">
                  <Button
                    size="small"
                    variant="primary"
                    theme="dark"
                    data-testid="create-app-button"
                  >
                    Create New App
                  </Button>
                </Link>
              </div>
            }
          />
          <List
            layout="grid"
            data={apps}
            itemKey={(app) => app.clientId}
            renderItem={(app) => <AppListItem app={app} />}
          />
        </div>
      )}
    </>
  );
}

export default () => (
  <AuthenticatedLayout pageIdentifier="view-apps">
    <Apps />
  </AuthenticatedLayout>
);
