import { BasicComponentProps } from '@whoop/web-components';
import React, { ReactNode } from 'react';
import styles from './index.module.scss';

type DescriptionProps = {
  label?: ReactNode;
} & BasicComponentProps;

function Description({ label, children, ...props }: DescriptionProps) {
  return (
    <div className={styles.root} {...props}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.body}>{children}</div>
    </div>
  );
}

export default Description;
