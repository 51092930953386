import { BasicComponentProps } from '@whoop/web-components';
import React, { ReactNode } from 'react';
import styles from './index.module.scss';

type SectionProps = {
  title: ReactNode;
  action?: ReactNode;
} & BasicComponentProps;

function Section({ children, title, action, ...props }: SectionProps) {
  return (
    <div className={styles.root} {...props}>
      <div className={styles.header}>
        <h3>{title}</h3>
        {action && action}
      </div>
      {children}
    </div>
  );
}

export default Section;
