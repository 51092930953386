/* eslint-disable import/prefer-default-export */

const SAFE_REDIRECT_PATHS = [
  /^\/apps\/create\/?/,
  /^\/apps\/[a-f0-9-]+\/?/,
  /^\/apps\/[a-f0-9-]+\/edit\/?/,
  /^\/apps\/[a-f0-9-]+\/delete\/?/,
  /^\/team\/?/,
  /^\/team\/delete\/?/,
  /^\/team\/edit\/?/,
  /^\/team\/leave\/?/,
  /^\/team\/invite\/?/,
  /^\/team\/invite\/accept\/?/,
  /^\/terms\/?/,
  /^\/login\/?/,
];

function matchesSafePath(pathname: string): boolean {
  return (
    SAFE_REDIRECT_PATHS.flatMap((safeRedirect) =>
      pathname.match(safeRedirect),
    ).filter((x) => !!x).length > 0
  );
}

/**
 * A redirect is considered safe if it included in our allow list.
 */
function constructSafeRedirect(pathname: string) {
  return matchesSafePath(pathname) ? pathname : '/';
}

export { constructSafeRedirect };
