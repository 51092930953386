/* eslint-disable max-classes-per-file */

export class AlreadyPartOfTeamError extends Error {}
export class CannotLeaveTeamAsLastAdminError extends Error {}
export class NoUserByThatEmailError extends Error {}
export class TeamNameConflictError extends Error {}
export class CannotDeleteTeamBeforeDeletingAllAppsError extends Error {}
export class TeamInvitationExpired extends Error {}

export interface TeamDto {
  name: string;
  members: TeamMemberDto[];
}

export interface TeamMemberDto {
  name: string;
  email: string;
  avatar_url: string;
}

export interface Team {
  name: string;
  members: TeamMember[];
}

export interface TeamMember {
  name: string;
  email: string;
  avatarUrl?: string;
}

export interface TeamInvitationDto {
  token: string;
  email: string;
  team_id: string;
  created_at: string;
  created_by_user_id: number;
  updated_at: string;
  accepted_by_user_id?: number | null;
}

export interface TeamInvitation {
  token: string;
  email: string;
  teamId: string;
  createdAt: Date;
  createdByUserId: number;
  updatedAt: Date;
  acceptedByUserId?: number;
}

export interface TeamInvitationConsentDto {
  team_name: string;
}

export interface TeamInvitationConsent {
  teamName: string;
}
