import {
  LoginRequest,
  LoginResponse,
  LoginResponseDto,
} from 'common/types/auth';
import config from 'config';

const login = async ({
  email,
  password,
}: LoginRequest): Promise<LoginResponse> => {
  const response = await fetch(`${config.authBaseUrl}/v1/whoop/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      username: email,
      password,
      grant_type: 'password',
    }),
  });
  if (response.status >= 400) {
    throw new Error(response.statusText);
  }
  const loginResponse: LoginResponseDto = await response.json();
  localStorage.setItem('whoop.access_token', loginResponse.access_token);
  localStorage.setItem('whoop.refresh_token', loginResponse.refresh_token);
  localStorage.setItem('whoop.user_id', loginResponse.user.id.toString());
  return {
    userId: loginResponse.user.id,
    accessToken: loginResponse.access_token,
    refreshToken: loginResponse.refresh_token,
  };
};

const logout = async (): Promise<void> => {
  localStorage.removeItem('whoop.access_token');
  localStorage.removeItem('whoop.refresh_token');
  localStorage.removeItem('whoop.user_id');
};

const getAccessToken = () => localStorage.getItem('whoop.access_token');

const getUserId = () => Number(localStorage.getItem('whoop.user_id'));

export default { login, logout, getAccessToken, getUserId };
