import Button from 'common/components/Button';
import DarkmodeCheckbox from 'common/components/DarkmodeCheckbox';
import LinkOut from 'common/components/LinkOut';
import AuthenticatedLayout from 'common/layouts/AuthenticatedLayout';
import terms from 'common/services/terms';
import { constructSafeRedirect } from 'common/utils';
import config from 'config';
import React, { useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useSearchParams } from 'react-router-dom';
import styles from './index.module.scss';

function Terms() {
  const [searchParams] = useSearchParams();
  const [acknowledged, setAcknowledged] = useState(false);

  const acceptTerms = useAsyncCallback(async () => {
    await terms.acceptTerms();
    window.location.href = constructSafeRedirect(
      searchParams.get('redir') || '/',
    );
  });

  return (
    <div className={styles.root}>
      <h1>API Terms of Use</h1>
      <p>
        Before accessing the WHOOP API, you must agree to the{' '}
        <LinkOut href={config.apiTermsOfUseUrl}>WHOOP API Terms of Use</LinkOut>
        .
      </p>
      <p>
        We take member privacy seriously. Learn more at our{' '}
        <LinkOut href={config.whoopPrivacyPolicyUrl}>Privacy Policy</LinkOut>.
      </p>

      <div className={styles.acknowledgeArea}>
        <div className={styles.checkboxArea}>
          <DarkmodeCheckbox
            id="accept-terms"
            checked={acknowledged}
            disabled={acceptTerms.loading}
            onClick={() => {
              setAcknowledged(!acknowledged);
            }}
            data-testid="acknowledge-checkbox"
          />
          <span className={styles.checkboxText}>
            I acknowledge that I have read and agree to the{' '}
            <LinkOut href={config.apiTermsOfUseUrl}>
              WHOOP API Terms of Use
            </LinkOut>
            .
          </span>
        </div>
        <Button
          className={styles.acceptButton}
          disabled={!acknowledged || acceptTerms.loading}
          variant="primary"
          theme="dark"
          onClick={acceptTerms.execute}
          data-testid="accept-terms-button"
        >
          {acceptTerms.loading ? 'Accepting' : 'Accept'}
        </Button>
      </div>
    </div>
  );
}

export default () => (
  <AuthenticatedLayout pageIdentifier="terms">
    <Terms />
  </AuthenticatedLayout>
);
