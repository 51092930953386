import { BasicComponentProps, Icon } from '@whoop/web-components';
import React, { ReactNode } from 'react';
import Dropdown from '../Dropdown';
import styles from './index.module.scss';

type PageHeaderProps = {
  banner?: ReactNode;
  title: ReactNode;
  actions?: ReactNode;
  overflowActions?: ReactNode;
} & BasicComponentProps;

function PageHeader({
  banner,
  title,
  actions,
  overflowActions,
  ...props
}: PageHeaderProps) {
  return (
    <div>
      {banner && banner}

      <div className={styles.root} {...props}>
        <div>
          <h1 data-testid="page-header-title">{title}</h1>
        </div>

        <div className={styles.actions}>
          {actions && actions}
          {overflowActions && (
            <Dropdown overlay={<div>{overflowActions}</div>}>
              <Icon name="overflow_menu" className={styles.moreOptionsIcon} />
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  );
}

export default PageHeader;
