import { Button, FormInput, FormInputProps, Icon } from '@whoop/web-components';
import React from 'react';
import { Control, FieldValues, useFieldArray } from 'react-hook-form';
import styles from './index.module.scss';

interface MultiInputProps extends Omit<FormInputProps, 'label'> {
  name: string;
  control: Control<FieldValues, any>;
  min?: number;
  addText?: string;
  label?: (index: number) => string;
}

function MultiInput({
  name,
  control,
  min = 0,
  placeholder,
  addText = 'Add',
  label,
  ...props
}: MultiInputProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <div>
      {fields.map((field, index) => (
        <div key={field.id} className={styles.inputWrapper}>
          <FormInput
            name={`${name}.${index}.value`}
            // @ts-ignore
            theme="dark"
            // @ts-ignore
            label={label && label(index)}
            {...props}
          />
          {index >= min && (
            <Button
              size="small"
              variant="link"
              theme="dark"
              onClick={() => remove(index)}
            >
              <Icon name="delete" />
            </Button>
          )}
        </div>
      ))}
      <Button
        variant="normal"
        theme="dark"
        onClick={() => append({ value: '' })}
      >
        {addText}
      </Button>
    </div>
  );
}

export default MultiInput;
