import { Button, Form, FormInput, FormInputType } from '@whoop/web-components';
import ErrorComponent from 'common/components/Error';
import PageHeader from 'common/components/PageHeader';
import { UnexpectedError } from 'common/errors';
import AuthenticatedLayout from 'common/layouts/AuthenticatedLayout';
import team from 'common/services/team';
import { TeamNameConflictError } from 'common/types/team';
import React from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';

function EditTeam() {
  const navigate = useNavigate();
  const getTeamTask = useAsync(team.getTeam, []);
  const updateTeamTask = useAsyncCallback(async (v) => {
    try {
      await team.updateTeam(v.name);
      navigate('/team', { replace: true });
    } catch (e) {
      if (e instanceof TeamNameConflictError) {
        throw new Error('That team name is taken, choose another!');
      }
      throw new UnexpectedError();
    }
  });

  return (
    <>
      <PageHeader title="Edit Team" />

      <Form onSubmit={updateTeamTask.execute}>
        {updateTeamTask.error && (
          <ErrorComponent data-testid="form-error">
            {updateTeamTask.error.message}
          </ErrorComponent>
        )}
        <div className={styles.formContents}>
          {getTeamTask.result?.name ? (
            <FormInput
              required={{ value: true, message: 'Team name cannot be empty' }}
              label="Team Name"
              name="name"
              type={FormInputType.TEXT}
              // @ts-ignore
              theme="dark"
              defaultValue={getTeamTask.result?.name || ''}
              data-testid="team-name-input"
            />
          ) : (
            <h1 style={{ width: 200 }}>
              <Skeleton />
            </h1>
          )}
        </div>
        <Button
          variant="normal"
          type="submit"
          theme="dark"
          disabled={getTeamTask.loading}
          data-testid="edit-team-button"
        >
          Confirm
        </Button>
      </Form>
    </>
  );
}

export default () => (
  <AuthenticatedLayout pageIdentifier="edit-team">
    <EditTeam />
  </AuthenticatedLayout>
);
