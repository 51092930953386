import { Button } from '@whoop/web-components';
import DefaultPageLoader from 'common/components/DefaultPageLoader';
import ErrorComponent from 'common/components/Error';
import { UnexpectedError } from 'common/errors';
import AuthenticatedLayout from 'common/layouts/AuthenticatedLayout';
import teamService from 'common/services/team';
import {
  AlreadyPartOfTeamError,
  TeamInvitationConsent,
  TeamInvitationExpired,
} from 'common/types/team';
import React from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useSearchParams } from 'react-router-dom';
import styles from './index.module.scss';

interface AcceptInvitationProps {
  consentInfo: TeamInvitationConsent;
  token: string;
}

function InvitationConsent({ consentInfo, token }: AcceptInvitationProps) {
  const acceptInviteTask = useAsyncCallback(async () => {
    try {
      await teamService.acceptTeamInvitation(token);
      window.location.href = '/';
    } catch (e) {
      if (e instanceof TeamInvitationExpired) {
        throw new Error('This invitation has expired.');
      } else if (e instanceof AlreadyPartOfTeamError) {
        throw new Error(
          'You are already a part of a team. You must leave or delete your team before joining another one.',
        );
      } else {
        throw new UnexpectedError();
      }
    }
  });

  return (
    <>
      <h1>You have been invited to join {consentInfo.teamName}</h1>
      <p>
        As a part of {consentInfo.teamName} you will be able to manage your
        team, create new apps, and more.
      </p>
      {acceptInviteTask.error?.message && (
        <ErrorComponent style={{ margin: '12px 0' }}>
          {acceptInviteTask.error.message}
        </ErrorComponent>
      )}
      <Button
        type="submit"
        variant="normal"
        theme="dark"
        disabled={acceptInviteTask.loading}
        data-testid="accept-invite-button"
        onClick={acceptInviteTask.execute}
      >
        Join Team
      </Button>
    </>
  );
}

function AcceptTeamInvitation() {
  const [params] = useSearchParams();
  const getInviteConsentTask = useAsync(async () => {
    const inviteCode = params.get('invite');
    if (!inviteCode) throw new Error('Invalid invite');
    try {
      const consent = await teamService.getInvitationConsentByToken(inviteCode);
      return consent;
    } catch (e) {
      if (e instanceof TeamInvitationExpired) {
        throw new Error('This invitation has expired.');
      } else {
        throw new UnexpectedError();
      }
    }
  }, [params]);

  const token = params.get('invite');

  return (
    <div className={styles.root}>
      {getInviteConsentTask.loading && <DefaultPageLoader />}
      {getInviteConsentTask.error && (
        <ErrorComponent>{getInviteConsentTask.error.message}</ErrorComponent>
      )}
      {getInviteConsentTask.result && token && (
        <InvitationConsent
          consentInfo={getInviteConsentTask.result}
          token={token}
        />
      )}
    </div>
  );
}

export default () => (
  <AuthenticatedLayout pageIdentifier="accept-team-invitation">
    <AcceptTeamInvitation />
  </AuthenticatedLayout>
);
