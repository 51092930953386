import { Logo } from '@whoop/web-components';
import useAuth from 'common/context/useAuth';
import React, { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styles from './index.module.scss';

interface RequireAuthProps {
  children: ReactNode;
}

function RequireAuth({ children }: RequireAuthProps) {
  const location = useLocation();
  const { user, loading: authLoading } = useAuth();

  if (!user) {
    if (authLoading) {
      return (
        <div className={styles.root}>
          <Logo variant="circle" />
        </div>
      );
    }

    return (
      <Navigate
        to={
          location.pathname === '/'
            ? '/login'
            : `/login?redir=${`${location.pathname}${
                location.search ? `${location.search}` : ''
              }`}`
        }
        replace
      />
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default RequireAuth;
