/* eslint-disable max-classes-per-file */
export class AppLimitReachedOrExceededError extends Error {}
export class CannotDeleteAppAsNonAdminError extends Error {}

export interface App {
  name: string;
  clientId: string;
  clientSecret: string;
  contacts: string[];
  logoUrl: string;
  privacyPolicyUrl?: string;
  redirectUrls: string[];
  scopes: string[];
  availableScopes: string[];
  dayRequestLimit: number;
  minuteRequestLimit: number;
  adoptedUserLimit?: number;
  adoptedUserCount: number;
  webhooks: { id: string; url: string }[];
}

export interface CreateAppRequest {
  name: string;
  contacts: string[];
  logoUrl?: string;
  privacyPolicyUrl?: string;
  redirectUrls: string[];
  scopes: string[];
  webhookUrl?: string;
}

export interface UpdateAppRequest {
  clientId: string;
  name: string;
  contacts: string[];
  logoUrl?: string;
  privacyPolicyUrl?: string;
  redirectUrls: string[];
  scopes: string[];
  webhookId?: string;
  webhookUrl?: string;
}

export interface AppDto {
  name: string;
  client_id: string;
  client_secret: string;
  contacts: string[];
  logo_url: string | null;
  owner: string;
  privacy_policy_url: string | null;
  redirect_urls: string[];
  scopes: string[];
  day_request_limit: number;
  minute_request_limit: number;
  adopted_user_limit: number | null;
  adopted_user_count: number;
}

export const SCOPES = [
  {
    scope: 'read:recovery',
    description:
      'Allows access to recovery information, including score, heart rate variability, and resting heart rate',
  },
  {
    scope: 'read:cycles',
    description:
      'Allows access to cycles information, including day strain and average heart rate during a cycle',
  },
  {
    scope: 'read:sleep',
    description:
      'Allows access to sleep information, such as duration and start and end time',
  },
  {
    scope: 'read:workout',
    description:
      'Allows access to workout information, including activity type and accumulated strain',
  },
  {
    scope: 'read:profile',
    description: 'Allows access to profile information',
  },
  {
    scope: 'read:body_measurement',
    description:
      'Allows access to body measurements, such as height, weight, and max heart rate',
  },
];

export const LEGACY_SCOPES = [
  {
    scope: 'read:team',
    description: 'Allows access to information regarding teams and communities',
  },
  {
    scope: 'read:journal',
    description: 'Allows access to journal information',
  },
];

export const ALL_SCOPES_INCLUDING_LEGACY = [...SCOPES, ...LEGACY_SCOPES];
