import AppForm, { AppFormValues } from 'apps/common/AppForm';
import DefaultPageLoader from 'common/components/DefaultPageLoader';
import ErrorComponent from 'common/components/Error';
import PageHeader from 'common/components/PageHeader';
import AuthenticatedLayout from 'common/layouts/AuthenticatedLayout';
import apps from 'common/services/apps';
import {
  AppLimitReachedOrExceededError,
  UpdateAppRequest,
} from 'common/types/apps';
import React from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useNavigate, useParams } from 'react-router-dom';

function EditApp() {
  const params = useParams();
  const navigate = useNavigate();
  const getAppTask = useAsync(() => apps.getApp(params.id || 'asdf'), []);
  const app = getAppTask.result;

  const toUpdateAppRequest = (formValues: AppFormValues): UpdateAppRequest => ({
    clientId: params.id || '',
    name: formValues.name,
    contacts: formValues.contacts.map((c) => c.value),
    redirectUrls: formValues.redirectUrls.map((r) => r.value),
    privacyPolicyUrl: formValues.privacyPolicyUrl,
    scopes: Object.entries(formValues.scopes)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([scope, isChecked]) => isChecked)
      .map(([s]) => s),
    logoUrl: formValues.logoUrl,
    webhookId: formValues.webhookId,
    webhookUrl: formValues.webhookUrl,
  });

  const updateAppTask = useAsyncCallback(async (v: AppFormValues) => {
    try {
      const updatedApp = await apps.updateApp(toUpdateAppRequest(v));
      navigate(`/apps/${updatedApp.clientId}`);
    } catch (e: any) {
      if (e instanceof AppLimitReachedOrExceededError) {
        throw new Error(
          "You've exceeded your app limit. To request an app increase, please submit a request via https://whoopinc.typeform.com/to/XmzituEp.",
        );
      } else {
        throw new Error(e);
      }
    }
  });

  return (
    <>
      {getAppTask.loading && <DefaultPageLoader />}
      {getAppTask.error && (
        <ErrorComponent>{getAppTask.error.message}</ErrorComponent>
      )}
      {app && (
        <div>
          <PageHeader title={`Edit: ${app.name}`} />
          <AppForm
            availableScopes={app.availableScopes}
            onSubmit={updateAppTask.execute}
            defaultValues={{
              contacts: app.contacts.map((c) => ({ value: c })),
              redirectUrls: app.redirectUrls.map((r) => ({ value: r })),
              name: app.name,
              privacyPolicyUrl: app.privacyPolicyUrl || '',
              scopes: app.scopes
                .filter((x) => x !== 'offline')
                .reduce((obj: Record<string, boolean>, current: string) => {
                  const newObj = { ...obj };
                  newObj[current] = true;
                  return newObj;
                }, {}),
              logoUrl: app.logoUrl,
              webhookId:
                app.webhooks.length > 0 ? app.webhooks[0].id : undefined,
              webhookUrl:
                app.webhooks.length > 0 ? app.webhooks[0].url : undefined,
            }}
            cta="Update App"
            loading={updateAppTask.loading}
            error={updateAppTask.error?.message}
          />
        </div>
      )}
    </>
  );
}

export default () => (
  <AuthenticatedLayout pageIdentifier="edit-app">
    <EditApp />
  </AuthenticatedLayout>
);
