import { Form, FormInput, FormInputType, useForm } from '@whoop/web-components';
import Button from 'common/components/Button';
import Error from 'common/components/Error';
import useAuth from 'common/context/useAuth';
import CenteredCardLayout from 'common/layouts/CenteredCardLayout';
import { constructSafeRedirect } from 'common/utils';
import config from 'config';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './index.module.scss';

function Login() {
  const methods = useForm({
    mode: 'onChange',
  });
  const [searchParams] = useSearchParams();
  const [authenticating, setAuthenticating] = useState(false);
  const [authError, setAuthError] = useState<string | undefined>(undefined);
  const { login } = useAuth();

  const hasEnteredEmail = !!methods.watch('email');
  const hasEnteredPassword = !!methods.watch('password');

  const doLogin = async (v: Record<string, any>) => {
    setAuthenticating(true);
    setAuthError(undefined);
    try {
      await login({
        email: v.email,
        password: v.password,
      });
      window.location.href = constructSafeRedirect(
        searchParams.get('redir') || '/',
      );
    } catch (e) {
      setAuthError('Invalid Email or Password');
    } finally {
      setAuthenticating(false);
    }
  };

  return (
    <>
      <h2 className={styles.signInText}>Sign in to WHOOP</h2>
      {authError && <Error data-testid="form-error">{authError}</Error>}
      <Form
        methods={methods}
        onSubmit={(v: any) => doLogin(v)}
        className={styles.formContainer}
      >
        <FormInput
          name="email"
          label="Email"
          type={FormInputType.TEXT}
          className={styles.formElement}
          data-testid="email-input"
          // @ts-ignore
          theme="dark"
        />

        <FormInput
          name="password"
          label="Password"
          type={FormInputType.PASSWORD}
          className={styles.formElement}
          data-testid="password-input"
          // @ts-ignore
          theme="dark"
        />

        <Button
          label="Log in"
          variant="primary"
          theme="dark"
          type="submit"
          className={styles.signInButton}
          disabled={authenticating || !hasEnteredEmail || !hasEnteredPassword}
          data-testid="login-button"
        />
      </Form>
      <a
        href={config.forgotPasswordUrl}
        className={styles.forgotPasswordLink}
        target="_blank"
        rel="noreferrer"
        data-testid="forgot-password-link"
      >
        Forgot your password?
      </a>
    </>
  );
}

export default () => (
  <CenteredCardLayout>
    <Login />
  </CenteredCardLayout>
);
