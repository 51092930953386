import Button from 'common/components/Button';
import LinkOut from 'common/components/LinkOut';
import Prompt from 'common/components/Prompt';
import config from 'config';
import React from 'react';
import { Link } from 'react-router-dom';

function CreateAppPrompt() {
  return (
    <Prompt
      title="Get Started with the WHOOP API"
      body={
        <p>
          Now that you have created your team, you are ready to unlock the power
          of the WHOOP API.
          <br />
          <br />
          Get started by creating an app, or head straight to our documentation.
        </p>
      }
      cta={
        <span>
          <Link to="/apps/create">
            <Button
              variant="primary"
              style={{ marginRight: 10 }}
              theme="dark"
              data-testid="create-app-button"
            >
              Create App
            </Button>
          </Link>

          <LinkOut href={config.docs.developerPlatformOverview}>
            <Button
              variant="link"
              theme="dark"
              data-testid="show-me-the-docs-button"
            >
              Show me the docs
            </Button>
          </LinkOut>
        </span>
      }
      data-testid="create-app-prompt"
    />
  );
}

export default CreateAppPrompt;
