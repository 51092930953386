import analytics from '@whoop/web-analytics';
import '@whoop/web-components/dist/index.css';
import CreateApp from 'apps/create';
import DeleteApp from 'apps/delete';
import AppDetails from 'apps/details';
import EditApp from 'apps/edit';
import AppList from 'apps/list';
import RequireAuth from 'common/components/RequireAuth';
import RequireTeam from 'common/components/RequireTeam';
import RequireTerms from 'common/components/RequireTerms';
import { AuthProvider } from 'common/context/useAuth';
import Login from 'login';
import NotFound from 'notfound';
import React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Team from 'team';
import CreateTeam from 'team/create';
import DeleteTeam from 'team/delete';
import EditTeam from 'team/edit';
import InviteTeamMember from 'team/invite';
import AcceptTeamInvitation from 'team/invite/accept';
import LeaveTeam from 'team/leave';
import Terms from 'terms';
import config from './config';

analytics.initialize({
  datadog: {
    applicationId: config.datadogApplicationId,
    clientToken: config.datadogClientToken,
    service: config.datadogService,
    env: config.env,
  },
});

function App() {
  return (
    <SkeletonTheme baseColor="#23262b" highlightColor="#333">
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route
              index
              element={
                <RequireAuth>
                  <RequireTerms>
                    <RequireTeam>
                      <AppList />
                    </RequireTeam>
                  </RequireTerms>
                </RequireAuth>
              }
            />
            <Route path="apps">
              <Route
                path="create"
                element={
                  <RequireAuth>
                    <RequireTerms>
                      <RequireTeam>
                        <CreateApp />
                      </RequireTeam>
                    </RequireTerms>
                  </RequireAuth>
                }
              />
              <Route path=":id">
                <Route
                  index
                  element={
                    <RequireAuth>
                      <RequireTerms>
                        <RequireTeam>
                          <AppDetails />
                        </RequireTeam>
                      </RequireTerms>
                    </RequireAuth>
                  }
                />
                <Route
                  path="edit"
                  element={
                    <RequireAuth>
                      <RequireTerms>
                        <RequireTeam>
                          <EditApp />
                        </RequireTeam>
                      </RequireTerms>
                    </RequireAuth>
                  }
                />
                <Route
                  path="delete"
                  element={
                    <RequireAuth>
                      <RequireTerms>
                        <RequireTeam>
                          <DeleteApp />
                        </RequireTeam>
                      </RequireTerms>
                    </RequireAuth>
                  }
                />
              </Route>
            </Route>
            <Route
              path="terms"
              element={
                <RequireAuth>
                  <Terms />
                </RequireAuth>
              }
            />
            <Route path="team">
              <Route
                index
                element={
                  <RequireAuth>
                    <RequireTerms>
                      <RequireTeam>
                        <Team />
                      </RequireTeam>
                    </RequireTerms>
                  </RequireAuth>
                }
              />
              <Route
                path="leave"
                element={
                  <RequireAuth>
                    <RequireTerms>
                      <RequireTeam>
                        <LeaveTeam />
                      </RequireTeam>
                    </RequireTerms>
                  </RequireAuth>
                }
              />
              <Route
                path="delete"
                element={
                  <RequireAuth>
                    <RequireTerms>
                      <RequireTeam>
                        <DeleteTeam />
                      </RequireTeam>
                    </RequireTerms>
                  </RequireAuth>
                }
              />
              <Route path="invite">
                <Route
                  index
                  element={
                    <RequireAuth>
                      <RequireTerms>
                        <RequireTeam>
                          <InviteTeamMember />
                        </RequireTeam>
                      </RequireTerms>
                    </RequireAuth>
                  }
                />
                <Route
                  path="accept"
                  element={
                    <RequireAuth>
                      <RequireTerms>
                        <AcceptTeamInvitation />
                      </RequireTerms>
                    </RequireAuth>
                  }
                />
              </Route>
              <Route
                path="edit"
                element={
                  <RequireAuth>
                    <RequireTerms>
                      <RequireTeam>
                        <EditTeam />
                      </RequireTeam>
                    </RequireTerms>
                  </RequireAuth>
                }
              />
              <Route
                path="create"
                element={
                  <RequireAuth>
                    <RequireTerms>
                      <CreateTeam />
                    </RequireTerms>
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </SkeletonTheme>
  );
}

export default App;
