import { Alert, Alerts } from '@whoop/web-components';
import ConfirmationPrompt from 'common/components/ConfirmationPrompt';
import DefaultPageLoader from 'common/components/DefaultPageLoader';
import ErrorComponent from 'common/components/Error';
import AuthenticatedLayout from 'common/layouts/AuthenticatedLayout';
import apps from 'common/services/apps';
import { CannotDeleteAppAsNonAdminError } from 'common/types/apps';
import React from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useParams } from 'react-router-dom';

function DeleteApp() {
  const params = useParams();
  const appId = params.id || 'asdf';
  const deleteAppTask = useAsyncCallback(async () => {
    try {
      await apps.deleteApp(appId);
      window.location.href = '/';
    } catch (e: any) {
      if (e instanceof CannotDeleteAppAsNonAdminError) {
        throw new Error(
          'You must be an admin for your team to delete this app.',
        );
      }
      throw e;
    }
  });
  const getAppTask = useAsync(apps.getApp, [appId]);
  const app = getAppTask.result;

  return (
    <>
      {getAppTask.loading && <DefaultPageLoader />}
      {getAppTask.error && (
        <ErrorComponent>{getAppTask.error?.message}</ErrorComponent>
      )}
      {app && (
        <ConfirmationPrompt
          title={`Are you sure you want to delete ${app.name}?`}
          body={
            app.adoptedUserCount > 0 ? (
              <ErrorComponent>
                Deleting this app impacts {app.adoptedUserCount} integrated
                users.
              </ErrorComponent>
            ) : (
              <Alert type={Alerts.INFO}>This app has 0 integrated users.</Alert>
            )
          }
          error={deleteAppTask.error?.message}
          loading={deleteAppTask.loading}
          confirmButtonText="Delete App"
          confirmationPhrase="delete app"
          onConfirm={deleteAppTask.execute}
        />
      )}
    </>
  );
}

export default () => (
  <AuthenticatedLayout pageIdentifier="delete-app">
    <DeleteApp />
  </AuthenticatedLayout>
);
