import ConfirmationPrompt from 'common/components/ConfirmationPrompt';
import DefaultPageLoader from 'common/components/DefaultPageLoader';
import AuthenticatedLayout from 'common/layouts/AuthenticatedLayout';
import teamService from 'common/services/team';
import { CannotDeleteTeamBeforeDeletingAllAppsError } from 'common/types/team';
import React from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';

function DeleteTeam() {
  const getTeamTask = useAsync(teamService.getTeam, []);

  const deleteTeamTask = useAsyncCallback(async () => {
    try {
      await teamService.deleteTeam();
      window.location.href = '/';
    } catch (e: any) {
      if (e instanceof CannotDeleteTeamBeforeDeletingAllAppsError) {
        throw new Error(
          'Cannot delete your team as there are active apps. You must first delete all apps before you can delete your team.',
        );
      }
      throw e;
    }
  });

  const team = getTeamTask.result;
  return (
    <>
      {getTeamTask.loading && <DefaultPageLoader />}
      {team && (
        <ConfirmationPrompt
          title={`Are you sure you want to delete ${team.name}?`}
          confirmationPhrase="delete team"
          confirmButtonText="Confirm"
          loading={getTeamTask.loading || deleteTeamTask.loading}
          error={getTeamTask.error?.message || deleteTeamTask.error?.message}
          onConfirm={deleteTeamTask.execute}
        />
      )}
    </>
  );
}

export default () => (
  <AuthenticatedLayout pageIdentifier="delete-team">
    <DeleteTeam />
  </AuthenticatedLayout>
);
