import { BasicComponentProps } from '@whoop/web-components';
import Avatar from 'common/components/Avatar';
import { TeamMember } from 'common/types/team';
import React from 'react';
import styles from './index.module.scss';

type TeamMemberProps = {
  member: TeamMember;
} & BasicComponentProps;

function TeamMemberListItem({ member }: TeamMemberProps) {
  return (
    <div className={styles.root}>
      <Avatar className={styles.avatar} src={member.avatarUrl} />
      <div>
        <p className={styles.name}>{member.name}</p>
        <p className={styles.email}>{member.email}</p>
      </div>
    </div>
  );
}

export default TeamMemberListItem;
