import { Icon } from '@whoop/web-components';
import useIsMobile from 'common/hooks/useIsMobile';
import React, { ReactNode } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import styles from './index.module.scss';

export interface NavLinkProps extends Omit<LinkProps, 'to'> {
  to: string;
  text: ReactNode;
  testId: string;
}

const isRelativePath = (path: string): boolean => path.startsWith('/');

function InnerContents({ text, to }: Omit<NavLinkProps, 'testId'>) {
  const location = useLocation();
  const isMobile = useIsMobile();

  return (
    <div className={styles.navLink}>
      <div
        className={
          location.pathname === to ? styles.currentLocationUnderline : undefined
        }
      >
        {text}
      </div>

      {isMobile && <Icon className={styles.rightArrow} name="arrow_right" />}
    </div>
  );
}

function NavLink({ to, testId, ...props }: NavLinkProps) {
  if (isRelativePath(to)) {
    return (
      <Link to={to} {...props} data-testid={testId}>
        <InnerContents to={to} {...props} />
      </Link>
    );
  }
  return (
    <a href={to} {...props} data-testid={testId}>
      <InnerContents to={to} {...props} />
    </a>
  );
}

export default NavLink;
