import {
  Alert,
  Alerts,
  Button,
  Form,
  FormInput,
  FormInputType,
  useForm,
} from '@whoop/web-components';
import DefaultPageLoader from 'common/components/DefaultPageLoader';
import ErrorComponent from 'common/components/Error';
import { UnexpectedError } from 'common/errors';
import AuthenticatedLayout from 'common/layouts/AuthenticatedLayout';
import teamService from 'common/services/team';
import { Team } from 'common/types/team';
import React, { useState } from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import styles from './index.module.scss';

interface InvitationProps {
  team: Team;
}

function Invitation({ team }: InvitationProps) {
  const [emailSuccess, setEmailSuccess] = useState('');
  const methods = useForm({
    mode: 'onChange',
  });

  const inviteTeamMemberTask = useAsyncCallback(async (v: any) => {
    try {
      await teamService.inviteTeamMember(v.email);
      setEmailSuccess(v.email);
      methods.resetField('email');
    } catch (e) {
      throw new UnexpectedError();
    }
  });

  return (
    <>
      <h1>Invite to {team.name}</h1>
      <Form onSubmit={(v) => inviteTeamMemberTask.execute(v)} methods={methods}>
        {inviteTeamMemberTask.error && (
          <ErrorComponent data-testid="form-error">
            {inviteTeamMemberTask.error.message}
          </ErrorComponent>
        )}
        {!inviteTeamMemberTask.error && emailSuccess && (
          <Alert
            iconName="check"
            type={Alerts.SUCCESS}
            data-testid="invite-success-alert"
          >
            You successfully invited {emailSuccess} to join your team.
          </Alert>
        )}

        <div className={styles.formContents}>
          <p>
            Enter the email of the WHOOP member you want to invite to your team.
            Please note that you should use the email address associated with
            their WHOOP account.
          </p>
          <FormInput
            name="email"
            label="Email"
            type={FormInputType.TEXT}
            // @ts-ignore
            theme="dark"
            required={{ value: true, message: 'This field is required' }}
            pattern={{ value: /\S+@\S+\.\S+/, message: 'Invalid email' }}
            validate={(v: string) => {
              if (team.members.find((m) => m.email === v)) {
                return 'Already part of the team!';
              }
              return undefined;
            }}
            className={styles.emailInput}
            data-testid="email-input"
          />
        </div>

        <Button
          type="submit"
          variant="normal"
          theme="dark"
          disabled={inviteTeamMemberTask.loading || !methods.formState.isValid}
          data-testid="invite-button"
        >
          Invite
        </Button>
      </Form>
    </>
  );
}

function InviteTeamMember() {
  const getTeamTask = useAsync(teamService.getTeam, []);

  return (
    <div className={styles.root}>
      {getTeamTask.loading && <DefaultPageLoader />}
      {getTeamTask.error && (
        <ErrorComponent>{getTeamTask.error.message}</ErrorComponent>
      )}
      {getTeamTask.result && <Invitation team={getTeamTask.result} />}
    </div>
  );
}

export default () => (
  <AuthenticatedLayout pageIdentifier="invite-team-member">
    <InviteTeamMember />
  </AuthenticatedLayout>
);
