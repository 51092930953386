import { BasicComponentProps } from '@whoop/web-components';
import useOnClickOutside from 'common/hooks/useOnClickOutside';
import useOnKeyDown from 'common/hooks/useOnKeyDown';
import React, { ReactNode, useRef, useState } from 'react';
import styles from './index.module.scss';

export interface DropdownProps extends BasicComponentProps {
  overlay: ReactNode;
}

function Dropdown({ overlay, children, ...props }: DropdownProps) {
  const [visible, setVisible] = useState(false);

  const ref = useRef(null);

  const hide = () => setVisible(false);
  const show = () => setVisible(true);

  useOnKeyDown('Escape', hide);
  useOnClickOutside(ref, hide);

  return (
    <div className={styles.root} ref={ref} {...props}>
      {/* eslint-disable jsx-a11y/click-events-have-key-events */
      /* eslint-disable jsx-a11y/no-static-element-interactions */}
      <div onClick={show} className={styles.wrapper}>
        {children}
      </div>
      {visible && <div className={styles.tooltip}>{overlay}</div>}
    </div>
  );
}

export default Dropdown;
