import { BasicComponentProps, Icon } from '@whoop/web-components';
import c from 'classnames';
import React from 'react';
import styles from './index.module.scss';

type AvatarProps = {
  src?: string;
} & BasicComponentProps;

function Avatar({ src, className, ...props }: AvatarProps) {
  if (!src) {
    return (
      <Icon
        name="my_account"
        className={c(styles.placeholder, className)}
        {...props}
      />
    );
  }
  return (
    <img
      src={src}
      alt="avatar"
      className={c(styles.avatar, className)}
      {...props}
    />
  );
}

export default Avatar;
