import React from 'react';
import Skeleton from 'react-loading-skeleton';

function DefaultPageLoader() {
  return (
    <div>
      <h1 style={{ width: 300 }}>
        <Skeleton />
      </h1>

      <Skeleton count={5} />
    </div>
  );
}

export default DefaultPageLoader;
