import { Alert, Alerts } from '@whoop/web-components';
import { BasicComponentProps } from '@whoop/web-components/dist/types';
import React from 'react';
import styles from './index.module.scss';

function Error({ children, ...props }: BasicComponentProps) {
  return (
    <Alert type={Alerts.ERROR} className={styles.error} {...props}>
      {children}
    </Alert>
  );
}

export default Error;
