import { AnalyticsSource } from '../../types';
import initSegment from './initSegment.script';

export interface SegmentProps {
  key: string;
}

const isInitialized = (): boolean =>
  typeof window !== 'undefined' && window.analytics;

export default {
  initialize: (props) => {
    initSegment(props.key);
  },
  identify: (user) => {
    if (isInitialized()) {
      const { id, ...userProps } = user;
      window.analytics.identify(id, userProps);
    }
  },
  trackEvent: (eventName, context) => {
    if (isInitialized()) {
      window.analytics.track(eventName, context);
    }
  },
} as AnalyticsSource<SegmentProps>;
