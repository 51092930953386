import {
  datadogRum,
  RumInitConfiguration,
  RumResourceEvent,
} from '@datadog/browser-rum';
import { AnalyticsSource } from '../../types';

export interface DataDogConfig extends RumInitConfiguration {
  enableSessionReplay?: boolean;
}

export default {
  initialize: ({
    applicationId,
    clientToken,
    service,
    env,
    enableSessionReplay,
  }) => {
    datadogRum.init({
      applicationId,
      clientToken,
      service,
      env,
      site: 'datadoghq.com',
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      // remove token from view & resource urls before sending to DataDog
      beforeSend: (event) => {
        if (event.view) {
          event.view.url = event.view.url.replace(
            /token=[^&]*/,
            'token=REDACTED'
          );
        }
        if (event.resource) {
          // Casting event to RumResourceEvent type otherwise .resource is of type unknown
          (<RumResourceEvent>event).resource.url = (<RumResourceEvent>(
            event
          )).resource.url.replace(/token=[^&]*/, 'token=REDACTED');
        }
      },
    });

    if (enableSessionReplay) {
      datadogRum.startSessionReplayRecording();
    }
  },
} as AnalyticsSource<DataDogConfig>;
