import { BasicComponentProps } from '@whoop/web-components';
import React, { ReactNode } from 'react';
import styles from './index.module.scss';

type PromptProps = {
  title: ReactNode;
  body: ReactNode;
  cta?: ReactNode;
  centered?: boolean;
} & BasicComponentProps;

function Prompt({ title, body, cta, centered = false, ...props }: PromptProps) {
  return (
    <div className={centered ? styles.rootCentered : styles.root} {...props}>
      <h1>{title}</h1>
      {body && typeof body === 'string' && <p>{body}</p>}
      {body && typeof body !== 'string' && body}

      {cta && cta}
    </div>
  );
}

export default Prompt;
