import auth from 'common/services/auth';
import userService from 'common/services/user';
import { LoginRequest } from 'common/types/auth';
import { User } from 'common/types/user';
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface AuthContextType {
  user?: User;
  loading: boolean;
  error?: any;
  login: (loginRequest: LoginRequest) => Promise<{ id: number }>;
  logout: () => Promise<void>;
}

const AuthContext = React.createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [error, setError] = useState<any>();
  // Initialize loading as true, because initially we have to load the user
  const [loading, setLoading] = useState<boolean>(true);

  const initialUserLoad = async () => {
    const userId = auth.getUserId();
    try {
      if (userId) {
        const userResponse = await userService.getUser();
        setUser(userResponse);
      }
    } catch (e) {
      setError('Unauthorized');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initialUserLoad();
  }, []);

  const login = async (loginRequest: LoginRequest) => {
    const response = await auth.login(loginRequest);
    const userResponse = await userService.getUser();
    setUser(userResponse);
    return { id: response.userId };
  };

  const logout = async () => {
    await auth.logout();
    setUser(undefined);
    window.location.replace('/login');
  };

  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      login,
      logout,
    }),
    [user, loading, error],
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
}

export default function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
