import { BasicComponentProps } from '@whoop/web-components';
import React, { ReactNode } from 'react';
import styles from './index.module.scss';

export type ListProps<T> = {
  layout?: 'list' | 'grid';
  data?: T[];
  itemKey: (t: T) => string;
  renderItem: (t: T, index: number) => ReactNode;
} & BasicComponentProps;

function List<T>({
  layout = 'list',
  data = [],
  itemKey,
  renderItem,
}: ListProps<T>) {
  return (
    <div className={layout === 'grid' ? styles.grid : styles.list}>
      {data.map((keyable, index) => (
        <div
          className={layout === 'grid' ? styles.itemWrapper : undefined}
          key={itemKey(keyable)}
        >
          <div className={layout === 'grid' ? styles.item : undefined}>
            {renderItem(keyable, index)}
          </div>
        </div>
      ))}
    </div>
  );
}

export default List;
