import { Button } from '@whoop/web-components';
import React from 'react';
import { Link } from 'react-router-dom';
import Prompt from '../Prompt';

function CreateTeamPrompt() {
  return (
    <Prompt
      title="Create your team"
      body="Development teams let you work with your teammates in one place and access the same apps. Create your team to get started with the WHOOP API."
      cta={
        <Link to="/team/create">
          <Button
            variant="normal"
            theme="dark"
            data-testid="create-team-button"
          >
            Get Started
          </Button>
        </Link>
      }
      data-testid="create-team-prompt"
    />
  );
}

export default CreateTeamPrompt;
