import { DefaultErrorBoundary } from 'common/components/ErrorBoundary';
import React, { ReactNode } from 'react';
import Header from './Header';
import styles from './index.module.scss';

interface AuthenticatedLayoutProps {
  pageIdentifier: string;
  children: ReactNode;
}

function AuthenticatedLayout({
  pageIdentifier,
  children,
}: AuthenticatedLayoutProps) {
  return (
    <div className={styles.root} data-testid={`page-id-${pageIdentifier}`}>
      <Header />
      <div className={styles.content}>
        <DefaultErrorBoundary>{children}</DefaultErrorBoundary>
      </div>
    </div>
  );
}

export default AuthenticatedLayout;
