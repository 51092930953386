import useAuth from 'common/context/useAuth';
import AuthenticatedLayout from 'common/layouts/AuthenticatedLayout';
import React, { ReactNode } from 'react';
import CreateTeamPrompt from '../CreateTeamPrompt';

interface BasicProps {
  children?: ReactNode;
}

function RequireTeam({ children }: BasicProps) {
  const { user } = useAuth();

  if (!user?.teamId) {
    return (
      <AuthenticatedLayout pageIdentifier="create-team-prompt">
        <CreateTeamPrompt />
      </AuthenticatedLayout>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default RequireTeam;
