import { User, UserDto } from 'common/types/user';
import config from 'config';
import api from './api';

const getUser = async (): Promise<User> => {
  const userDto: UserDto = await api.get(`${config.apiBaseUrl}/v1/user`);

  return {
    userId: userDto.user_id,
    email: userDto.email,
    teamId: userDto.team_id,
    avatarUrl: userDto.avatar_url,
    hasAcceptedTerms: userDto.has_accepted_terms,
  };
};

export default { getUser };
