import {
  AlreadyPartOfTeamError,
  CannotDeleteTeamBeforeDeletingAllAppsError,
  CannotLeaveTeamAsLastAdminError,
  Team,
  TeamDto,
  TeamInvitation,
  TeamInvitationConsent,
  TeamInvitationConsentDto,
  TeamInvitationDto,
  TeamInvitationExpired,
  TeamNameConflictError,
} from 'common/types/team';
import config from 'config';
import api, { ApiError } from './api';

const saveTeam = async (action: () => Promise<Team>): Promise<Team> => {
  try {
    return await action();
  } catch (e: any) {
    if (e instanceof ApiError && e.response.status === 409) {
      let body;
      try {
        body = await e.response.text();
      } catch (errorParsingBody) {
        throw e;
      }
      if (body.includes('NAME_CONFLICT')) {
        throw new TeamNameConflictError();
      } else {
        throw new AlreadyPartOfTeamError();
      }
    }
    throw e;
  }
};

const mapDto = (teamDto: TeamDto): Team => ({
  ...teamDto,
  members: teamDto.members.map((member) => ({
    ...member,
    avatarUrl: member.avatar_url,
  })),
});

const createTeam = async (name: string): Promise<Team> =>
  saveTeam(async () => {
    const teamDto: TeamDto = await api.post(`${config.apiBaseUrl}/v1/team`, {
      name,
    });
    return mapDto(teamDto);
  });

const updateTeam = async (name: string): Promise<Team> =>
  saveTeam(async () => {
    const teamDto: TeamDto = await api.put(`${config.apiBaseUrl}/v1/team`, {
      name,
    });
    return mapDto(teamDto);
  });

const getTeam = async (): Promise<Team> => {
  const teamDto: TeamDto = await api.get(`${config.apiBaseUrl}/v1/team`);
  return mapDto(teamDto);
};

const leaveTeam = async (): Promise<void> => {
  try {
    await api.doDelete(`${config.apiBaseUrl}/v1/team/membership`);
  } catch (e: any) {
    if (
      e instanceof ApiError &&
      e.response.status === 400 &&
      (await e.response.text()).includes('only admin in the group')
    ) {
      throw new CannotLeaveTeamAsLastAdminError();
    } else {
      throw e;
    }
  }
};

const deleteTeam = async (): Promise<void> => {
  try {
    await api.doDelete(`${config.apiBaseUrl}/v1/team`);
  } catch (e: any) {
    if (
      e.response.status === 400 &&
      (await e.response.text()).includes(
        'All clients for a group must be deleted before deleting that group',
      )
    ) {
      throw new CannotDeleteTeamBeforeDeletingAllAppsError();
    }
    throw e;
  }
};

const mapInvitationDto = (dto: TeamInvitationDto): TeamInvitation => ({
  ...dto,
  teamId: dto.team_id,
  createdAt: new Date(dto.created_at),
  createdByUserId: dto.created_by_user_id,
  updatedAt: new Date(dto.updated_at),
  acceptedByUserId: dto.accepted_by_user_id || undefined,
});

const inviteTeamMember = async (email: string): Promise<TeamInvitation> => {
  const dto: TeamInvitationDto = await api.post(
    `${config.apiBaseUrl}/v1/team/invitation`,
    { email },
  );
  return mapInvitationDto(dto);
};

const getInvitationConsentByToken = async (
  token: string,
): Promise<TeamInvitationConsent> => {
  try {
    const res: TeamInvitationConsentDto = await api.get(
      `${config.apiBaseUrl}/v1/team/invitation/consent?token=${token}`,
    );
    return { teamName: res.team_name };
  } catch (e: any) {
    if (
      e instanceof ApiError &&
      (e.response.status === 404 || e.response.status === 403)
    ) {
      throw new TeamInvitationExpired();
    }
    throw e;
  }
};

const acceptTeamInvitation = async (token: string): Promise<void> => {
  try {
    await api.emptyPost(`${config.apiBaseUrl}/v1/team/invitation/accept`, {
      token,
    });
  } catch (e: any) {
    if (e instanceof ApiError && e.response.status === 409) {
      throw new AlreadyPartOfTeamError();
    }
    throw e;
  }
};

const getUnacceptedTeamInvitations = async (): Promise<TeamInvitation[]> => {
  const dtos: TeamInvitationDto[] = await api.get(
    `${config.apiBaseUrl}/v1/team/invitation/unaccepted`,
  );
  return dtos.map(mapInvitationDto);
};

const getTeamAndInvitations = async (): Promise<{
  team: Team;
  invitations: TeamInvitation[];
}> => {
  const team = await getTeam();
  const invitations = await getUnacceptedTeamInvitations();

  return { team, invitations };
};

export default {
  createTeam,
  getTeam,
  updateTeam,
  leaveTeam,
  deleteTeam,
  inviteTeamMember,
  getInvitationConsentByToken,
  acceptTeamInvitation,
  getTeamAndInvitations,
};
