import { ALL_SCOPES_INCLUDING_LEGACY } from 'common/types/apps';
import { isDefinedGuard } from 'common/utils';
import React, { useState } from 'react';
import Scope from '../Scope';

interface ScopeInputProps {
  scope: string;
  description: string;
  onSelect: (checked: boolean) => void;
  defaultValue?: boolean;
}

function ScopeInput({
  scope,
  description,
  onSelect,
  defaultValue = false,
}: ScopeInputProps) {
  const [value, setValue] = useState(defaultValue);
  return (
    <Scope
      {...{ scope, description }}
      checked={value}
      onClick={() => {
        setValue(!value);
        onSelect(!value);
      }}
    />
  );
}

interface ScopesFormProps {
  availableScopes: string[];
  defaultValues?: Record<string, boolean>;
  onSelect: (scope: string, checked: boolean) => void;
}

function ScopesForm({
  onSelect,
  availableScopes,
  defaultValues,
}: ScopesFormProps) {
  return (
    <div>
      {availableScopes
        .map((availableScopeName) =>
          ALL_SCOPES_INCLUDING_LEGACY.find(
            (s) => s.scope === availableScopeName,
          ),
        )
        .filter(isDefinedGuard)
        .map((scope) => (
          <div key={scope.scope}>
            <ScopeInput
              onSelect={(b) => {
                onSelect(scope.scope, b);
              }}
              {...scope}
              defaultValue={defaultValues && defaultValues[scope.scope]}
            />
          </div>
        ))}
    </div>
  );
}

export default ScopesForm;
