import { Icon, StatusTag, Statuses } from '@whoop/web-components';
import Button from 'common/components/Button';
import DefaultPageLoader from 'common/components/DefaultPageLoader';
import Description from 'common/components/Description';
import Error from 'common/components/Error';
import LinkOut from 'common/components/LinkOut';
import PageHeader from 'common/components/PageHeader';
import Section from 'common/components/Section';
import AuthenticatedLayout from 'common/layouts/AuthenticatedLayout';
import apps from 'common/services/apps';
import config from 'config';
import React from 'react';
import { useAsync } from 'react-async-hook';
import { Link, useParams } from 'react-router-dom';
import Scopes from '../common/Scopes';
import AppSecret from './AppSecret';
import styles from './index.module.scss';

function AppDetails() {
  const params = useParams();
  const getAppTask = useAsync(() => apps.getApp(params.id || 'asdf'), []);
  const app = getAppTask.result;
  return (
    <>
      {getAppTask.loading && <DefaultPageLoader />}
      {getAppTask.error && <Error>{getAppTask.error.message}</Error>}
      {app && (
        <div>
          <PageHeader
            title={app.name}
            actions={
              <div>
                <Link
                  to={`/apps/${app.clientId}/edit`}
                  data-testid="edit-app-button"
                >
                  <Button
                    variant="primary"
                    size="small"
                    theme="dark"
                    style={{
                      marginRight: 8,
                    }}
                  >
                    <Icon name="edit" />
                  </Button>
                </Link>
                <Link
                  to={`/apps/${app.clientId}/delete`}
                  data-testid="delete-app-button"
                >
                  <Button variant="normal" size="small" theme="dark">
                    <Icon name="delete" />
                  </Button>
                </Link>
              </div>
            }
          />

          {app.adoptedUserLimit && (
            <Section
              title={
                <>
                  Status&nbsp;&nbsp;
                  <StatusTag color={Statuses.INFO}>
                    {app.adoptedUserLimit - app.adoptedUserCount} Remaining test
                    users
                  </StatusTag>
                </>
              }
              data-testid="app-approval-section"
            >
              <Description>
                <div>
                  Apps can be used for development immediately with a limit of{' '}
                  {app.adoptedUserLimit} WHOOP members. To launch your app to
                  all WHOOP members, you must submit your app for approval.
                  <br />
                  <br />
                  <LinkOut href={config.appApprovalSubmissionUrl}>
                    <Button variant="primary" theme="dark">
                      Request Approval
                    </Button>
                  </LinkOut>
                  <LinkOut href={config.appApprovalLearnMoreUrl}>
                    <Button variant="link" theme="dark">
                      Learn More
                    </Button>
                  </LinkOut>
                </div>
              </Description>
            </Section>
          )}
          <Section title="Credentials">
            <Description label="Client ID">
              <pre>{app.clientId}</pre>
            </Description>
            <Description label="Client Secret">
              <AppSecret secret={app.clientSecret} />
            </Description>
          </Section>

          <Section title="Details">
            <Description label="Logo">
              <img className={styles.logo} src={app.logoUrl} alt={app.name} />
            </Description>
            <Description label="Contacts">
              {app.contacts.join(', ') || '-'}
            </Description>
            <Description label="Privacy Policy">
              {app.privacyPolicyUrl || '-'}
            </Description>
            <Description label="Redirect URLs">
              {app.redirectUrls.join(', ') || '-'}
            </Description>
            <Description label="Scopes" />
            <Scopes scopes={app.scopes} />
          </Section>

          <Section title="Webhooks">
            <p>
              <LinkOut href={config.docs.webhooks}>Learn more</LinkOut> about
              webhooks with WHOOP.
            </p>
            <Description label="Webhook URL">
              {app.webhooks.map((w) => w.url).join(', ') || '-'}
            </Description>
          </Section>

          <Section title="Rate Limits">
            <Description label="API Day Rate Limit">
              {app.dayRequestLimit}
            </Description>
            <Description label="API Minute Rate Limit">
              {app.minuteRequestLimit}
            </Description>
          </Section>
        </div>
      )}
    </>
  );
}

export default () => (
  <AuthenticatedLayout pageIdentifier="app-details">
    <AppDetails />
  </AuthenticatedLayout>
);
