import { Button, Form, FormInput, FormInputType } from '@whoop/web-components';
import React, { ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import Error from '../Error';
import styles from './index.module.scss';

interface ConfirmationPromptProps {
  title: ReactNode;
  body?: ReactNode;
  error?: string;
  loading?: boolean;
  confirmationPhrase: string;
  confirmButtonText: string;
  onConfirm: () => void;
}

function ConfirmationPrompt({
  title,
  body,
  error,
  loading,
  confirmationPhrase,
  confirmButtonText,
  onConfirm,
}: ConfirmationPromptProps) {
  const methods = useForm({
    mode: 'onChange',
  });

  return (
    <>
      <h1>{title}</h1>
      <Form onSubmit={onConfirm} methods={methods}>
        {error && <Error data-testid="form-error">{error}</Error>}

        <div className={styles.formContents}>
          {body && <div>{body}</div>}
          <p>
            To continue, type <i>{confirmationPhrase}</i> in the text input
            below.
          </p>
          <FormInput
            validate={{
              prefixMatch: (v) => {
                if (
                  v &&
                  (!confirmationPhrase.startsWith(v) ||
                    v.length > confirmationPhrase.length)
                ) {
                  return `Type "${confirmationPhrase}" to continue`;
                }
                return undefined;
              },
            }}
            name="confirmation"
            label={`${confirmationPhrase}`}
            type={FormInputType.TEXT}
            // @ts-ignore
            theme="dark"
            data-testid="confirmation-input"
          />
        </div>

        <Button
          type="submit"
          variant="normal"
          disabled={
            loading ||
            !methods.formState.isValid ||
            methods.watch('confirmation') !== confirmationPhrase
          }
          theme="dark"
          data-testid="confirmation-button"
        >
          {confirmButtonText}
        </Button>
      </Form>
    </>
  );
}

export default ConfirmationPrompt;
