import { Button, ButtonProps } from '@whoop/web-components';
import color from 'common/styles/color';
import React from 'react';

const ButtonShim = ({
  variant = 'normal',
  disabled = false,
  style = {},
  className,
  ...props
}: ButtonProps) => {
  if (variant === 'primary') {
    return (
      <Button
        {...{
          ...props,
          disabled,
          variant: 'normal',
          className,
          style: {
            ...style,
            color: color.primaryColor,
            borderColor: color.primaryColor,
            opacity: disabled ? 0.5 : 1,
          },
        }}
      />
    );
  }
  return <Button {...{ variant, disabled, style, className, ...props }} />;
};

export default ButtonShim;
