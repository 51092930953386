import { Icon } from '@whoop/web-components';
import DarkmodeCheckbox from 'common/components/DarkmodeCheckbox';
import React from 'react';
import styles from './index.module.scss';

interface ScopeProps {
  scope: string;
  description: string;
  checked?: boolean;
  disabled?: boolean;
  displayOnly?: boolean;
  onClick?: (e: any) => void;
}

function Scope({
  scope,
  checked,
  description,
  disabled = false,
  displayOnly = false,
  onClick = () => null,
}: ScopeProps) {
  return (
    <div className={styles.root}>
      {displayOnly ? (
        <Icon style={{ height: 20, width: 20 }} name="check" />
      ) : (
        <DarkmodeCheckbox
          id={`scope-${scope}`}
          checked={checked}
          disabled={disabled}
          onClick={onClick}
          data-testid={`scope-${scope.replace(':', '-')}`}
        />
      )}
      <div className={styles.scopeText}>
        <pre className={styles.scopePre}>{scope}</pre>
        <p className={styles.scopeDescription}>{description}</p>
      </div>
    </div>
  );
}

export default Scope;
