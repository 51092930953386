import config from 'config';
import api from '../../api';

interface AppWebhook {
  id: string;
  url: string;
}

const getWebhooksForClientId = async (
  clientId: string,
): Promise<AppWebhook[]> =>
  api.get(`${config.apiBaseUrl}/v1/webhook/url?clientId=${clientId}`);

const DEFAULT_ENABLED_WEBHOOK_TYPES = [
  'workout.updated',
  'workout.deleted',
  'sleep.updated',
  'sleep.deleted',
  'recovery.updated',
  'recovery.deleted',
];

const createWebhookForClientId = async (
  clientId: string,
  url: string,
): Promise<AppWebhook> =>
  api.post(`${config.apiBaseUrl}/v1/webhook/url`, {
    client_id: clientId,
    url,
    enabled_event_types: DEFAULT_ENABLED_WEBHOOK_TYPES,
  });

const updateWebhookByIdForClientId = async (
  id: string,
  clientId: string,
  url: string,
) =>
  api.post(`${config.apiBaseUrl}/v1/webhook/url/${id}`, {
    client_id: clientId,
    url,
    enabled_event_types: DEFAULT_ENABLED_WEBHOOK_TYPES,
  });

const deleteWebhookById = async (id: string): Promise<void> =>
  api.doDelete(`${config.apiBaseUrl}/v1/webhook/url/${id}`);

export default {
  getWebhooksForClientId,
  createWebhookForClientId,
  updateWebhookByIdForClientId,
  deleteWebhookById,
};
