import AppForm, { AppFormValues } from 'apps/common/AppForm';
import PageHeader from 'common/components/PageHeader';
import AuthenticatedLayout from 'common/layouts/AuthenticatedLayout';
import apps from 'common/services/apps';
import {
  AppLimitReachedOrExceededError,
  CreateAppRequest,
  SCOPES,
} from 'common/types/apps';
import React from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useNavigate } from 'react-router-dom';

function CreateApp() {
  const navigate = useNavigate();

  const toCreateAppRequest = (formValues: AppFormValues): CreateAppRequest => ({
    name: formValues.name,
    contacts: formValues.contacts.map((c) => c.value),
    redirectUrls: formValues.redirectUrls.map((r) => r.value),
    privacyPolicyUrl: formValues.privacyPolicyUrl,
    scopes: Object.entries(formValues.scopes)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([scope, isChecked]) => isChecked)
      .map(([s]) => s),
    logoUrl: formValues.logoUrl,
    webhookUrl: formValues.webhookUrl,
  });

  const createAppTask = useAsyncCallback(async (v: AppFormValues) => {
    try {
      const createdApp = await apps.createApp(toCreateAppRequest(v));
      navigate(`/apps/${createdApp.clientId}`);
    } catch (e: any) {
      if (e instanceof AppLimitReachedOrExceededError) {
        throw new Error(
          "You've exceeded your app limit. To request an app increase, please submit a request via https://whoopinc.typeform.com/to/XmzituEp.",
        );
      } else {
        throw new Error(e);
      }
    }
  });

  return (
    <>
      <PageHeader title="New App" />
      <AppForm
        availableScopes={SCOPES.map((s) => s.scope)}
        defaultValues={{
          contacts: [{ value: '' }],
          redirectUrls: [{ value: '' }],
          name: '',
          privacyPolicyUrl: '',
          scopes: {},
        }}
        cta="Create App"
        loading={createAppTask.loading}
        error={createAppTask.error?.message}
        onSubmit={createAppTask.execute}
      />
    </>
  );
}

export default () => (
  <AuthenticatedLayout pageIdentifier="create-app">
    <CreateApp />
  </AuthenticatedLayout>
);
