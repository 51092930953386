import Button from 'common/components/Button';
import DefaultPageLoader from 'common/components/DefaultPageLoader';
import PageHeader from 'common/components/PageHeader';
import useAuth from 'common/context/useAuth';
import useIsMobile from 'common/hooks/useIsMobile';
import AuthenticatedLayout from 'common/layouts/AuthenticatedLayout';
import teamService from 'common/services/team';
import { TeamMember } from 'common/types/team';
import { User } from 'common/types/user';
import React from 'react';
import { useAsync } from 'react-async-hook';
import { Link } from 'react-router-dom';
import TeamMemberListItem from './common/TeamMember';
import styles from './index.module.scss';

const sortBySelfThenByName = (a: TeamMember, b: TeamMember, self?: User) =>
  a.email === self?.email ? -1 : a.name.localeCompare(b.name);

function Team() {
  const { user } = useAuth();
  const isMobile = useIsMobile();
  const getTeamTask = useAsync(teamService.getTeamAndInvitations, []);
  const team = getTeamTask.result?.team;
  const invitations = getTeamTask.result?.invitations;

  return (
    <>
      {getTeamTask.loading && <DefaultPageLoader />}
      {team && invitations && (
        <div>
          <PageHeader
            title={team.name}
            actions={
              !isMobile && (
                <Link to="/team/invite">
                  <Button
                    size="small"
                    style={{ marginRight: 20 }}
                    variant="primary"
                    theme="dark"
                  >
                    Invite
                  </Button>
                </Link>
              )
            }
            overflowActions={
              <div>
                {isMobile && (
                  <Link to="/team/invite">
                    <Button
                      className={styles.actionButtonInDropdown}
                      size="small"
                      variant="normal"
                      theme="dark"
                    >
                      Invite
                    </Button>
                  </Link>
                )}
                <Link to="/team/edit">
                  <Button
                    className={styles.actionButtonInDropdown}
                    size="small"
                    variant="normal"
                    theme="dark"
                    data-testid="edit-team-button"
                  >
                    Edit Team
                  </Button>
                </Link>
                {team.members.length > 1 && (
                  <Link to="/team/leave">
                    <Button
                      className={styles.actionButtonInDropdown}
                      size="small"
                      variant="normal"
                      theme="dark"
                    >
                      Leave Team
                    </Button>
                  </Link>
                )}
                <Link to="/team/delete">
                  <Button
                    className={styles.actionButtonInDropdown}
                    size="small"
                    variant="normal"
                    theme="dark"
                  >
                    Delete Team
                  </Button>
                </Link>
              </div>
            }
          />
          {team.members
            .sort((a, b) => sortBySelfThenByName(a, b, user))
            .map((member) => (
              <TeamMemberListItem key={member.email} member={member} />
            ))}
          {invitations
            .sort((a, b) => a.email.localeCompare(b.email))
            .map((invitation) => (
              <TeamMemberListItem
                key={invitation.email}
                member={{
                  name: `Invitation sent ${invitation.createdAt.toLocaleDateString(
                    'en-us',
                    {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    },
                  )}`,
                  email: invitation.email,
                }}
              />
            ))}
        </div>
      )}
    </>
  );
}

export default () => (
  <AuthenticatedLayout pageIdentifier="view-team">
    <Team />
  </AuthenticatedLayout>
);
