import Description from 'common/components/Description';
import { App } from 'common/types/apps';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

interface AppProps {
  app: App;
}

function AppListItem({ app }: AppProps) {
  return (
    <Link to={`/apps/${app.clientId}`}>
      <div className={styles.root}>
        <h3 className={styles.name}>{app.name}</h3>
        <Description label="Client ID">
          <p className={styles.clientId}>{app.clientId}</p>
        </Description>
      </div>
    </Link>
  );
}

export default AppListItem;
