import { Form, FormInput, FormInputType } from '@whoop/web-components';
import ScopesSelect from 'apps/common/Scopes/ScopesSelect';
import Button from 'common/components/Button';
import Description from 'common/components/Description';
import ErrorComponent from 'common/components/Error';
import ImageUpload from 'common/components/ImageUpload';
import LinkOut from 'common/components/LinkOut';
import MultiInput from 'common/components/MultiInput';
import config from 'config';
import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import styles from './index.module.scss';

export interface AppFormValues {
  contacts: { value: string }[];
  redirectUrls: { value: string }[];
  name: string;
  privacyPolicyUrl: string;
  scopes: Record<string, boolean>;
  logoUrl?: string;
  // Webhook id is only ever populated in an edit/update scenario
  webhookId?: string;
  webhookUrl?: string;
}

interface AppFormProps {
  availableScopes: string[];
  defaultValues: AppFormValues;
  cta: string;
  onSubmit: (v: AppFormValues) => void;
  loading?: boolean;
  error?: string;
}

function AppForm({
  availableScopes,
  defaultValues,
  cta,
  loading = false,
  error,
  onSubmit,
}: AppFormProps) {
  const methods = useForm({
    defaultValues: defaultValues as FieldValues,
    mode: 'onBlur',
  });

  const noScopesSelected = !Object.values(methods.watch('scopes') || {}).find(
    (x) => x,
  );

  return (
    <div className={styles.root}>
      <Form onSubmit={(v) => onSubmit(v as AppFormValues)} methods={methods}>
        <Description label="Name">
          <p>App name displayed to the user in the OAuth flow.</p>
          <FormInput
            className={styles.formInput}
            name="name"
            type={FormInputType.TEXT}
            label="Name"
            required={{ value: true, message: 'Name is required' }}
            // @ts-ignore
            theme="dark"
            data-testid="name-input"
          />
        </Description>

        <Description label="Logo">
          <p>
            Logo displayed to the user in the OAuth flow. This logo must be
            either .jpg or .png and will be displayed in 1:1 width/height pixel
            ratio.
          </p>
          <ImageUpload
            setValue={methods.setValue}
            defaultValue={defaultValues.logoUrl}
            name="logoUrl"
          />
        </Description>

        <Description label="Contacts">
          <p>
            Contact emails for this app. Administrative communications from
            WHOOP concerning this app will be directed to these emails.
          </p>
          <MultiInput
            className={styles.formInput}
            control={methods.control}
            name="contacts"
            min={1}
            label={(i) => `Contact #${i + 1}`}
            type={FormInputType.TEXT}
            required={{ value: true, message: 'This field is required' }}
            pattern={{ value: /\S+@\S+\.\S+/, message: 'Invalid email' }}
            data-testid="contacts-multi-input"
          />
        </Description>

        <Description label="Privacy Policy">
          <p>
            Link to your privacy policy. Users will be presented with this link
            in the OAuth flow.
          </p>
          <FormInput
            className={styles.formInput}
            name="privacyPolicyUrl"
            label="Privacy Policy"
            type={FormInputType.TEXT}
            // @ts-ignore
            theme="dark"
            data-testid="privacy-policy-url-input"
          />
        </Description>

        <Description label="Redirect URLs">
          <p>
            Location to which the user is taken after logging in. A valid
            redirect URL will take the form of https://whoop.com or
            whoop://example.
            <br />
            <br />
            <LinkOut href={config.docs.oauthRedirects}>Learn more</LinkOut>{' '}
            about OAuth redirects.
          </p>
          <MultiInput
            className={styles.formInput}
            control={methods.control}
            name="redirectUrls"
            min={1}
            label={(i) => `Redirect #${i + 1}`}
            type={FormInputType.TEXT}
            required={{ value: true, message: 'This field is required' }}
            pattern={{
              value: /\S+:\/\/\S+/,
              message: 'Invalid redirect URL',
            }}
            data-testid="redirect-urls-multi-input"
          />
        </Description>

        <Description label="Scopes">
          <p>
            Scopes determine what data your app can access on a user&apos;s
            behalf.
            <br />
            <br />
            <LinkOut href={config.docs.oauthScopes}>Learn more</LinkOut> about
            OAuth scopes.
          </p>

          <ScopesSelect
            availableScopes={availableScopes}
            defaultValues={defaultValues?.scopes}
            onSelect={(scope, checked) => {
              methods.setValue(`scopes.${scope}`, checked);
            }}
          />
        </Description>

        <Description label="Webhook URL">
          <p>
            Webhook URL to receive updates from WHOOP. WHOOP will send updates
            to this URL when changes occur to member data.
            <br />
            <br />
            <LinkOut href={config.docs.webhooks}>Learn more</LinkOut> about
            Webhooks.
          </p>
          <FormInput
            className={styles.formInput}
            name="webhookUrl"
            label="Webhook URL"
            type={FormInputType.TEXT}
            // @ts-ignore
            theme="dark"
            validate={{
              startWithHttps: async (webhookUrl: string) => {
                if (webhookUrl && !webhookUrl.startsWith('https'))
                  return 'Webhook URL must start with "https"';
                return undefined;
              },
            }}
            data-testid="webhook-url-input"
          />
        </Description>

        <br />
        {error && (
          <ErrorComponent data-testid="form-error">{error}</ErrorComponent>
        )}
        <br />
        <Button
          type="submit"
          variant="primary"
          theme="dark"
          disabled={loading || !methods.formState.isValid || noScopesSelected}
          data-testid="submit-button"
        >
          {cta}
        </Button>
      </Form>
    </div>
  );
}

export default AppForm;
