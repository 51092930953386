import { Button } from '@whoop/web-components';
import NavLink, { NavLinkProps } from 'common/components/NavLink';
import useAuth from 'common/context/useAuth';
import useIsMobile from 'common/hooks/useIsMobile';
import React from 'react';
import styles from './index.module.scss';

interface HeaderMenuProps {
  navLinks: NavLinkProps[];
}

function HeaderMenu({ navLinks }: HeaderMenuProps) {
  const { user, logout } = useAuth();
  const isMobile = useIsMobile();

  const doLogout = () => {
    logout();
  };

  return (
    <div>
      <p className={styles.email}>{user?.email || '...'}</p>
      {isMobile && (
        <div>
          <hr />
          <br />
          {navLinks.map((navLink) => (
            <NavLink key={navLink.to} {...navLink} />
          ))}
        </div>
      )}
      <Button
        size="small"
        variant="normal"
        theme="dark"
        className={styles.logoutButton}
        onClick={() => doLogout()}
        data-testid="logout-button"
      >
        Logout
      </Button>
    </div>
  );
}

export default HeaderMenu;
