import segment, { SegmentProps } from './sources/segment';
import datadog, { DataDogConfig } from './sources/datadog';
import {
  AnalyticsContext,
  UserProfile,
  InitializeFunction,
  IdentifyFunction,
  EventFunction,
  AnalyticsSource,
  WhoopAnalytics,
} from './types';

interface WebAnalyticsProps {
  segment?: SegmentProps;
  datadog?: DataDogConfig;
}

export default {
  initialize: ({ segment: segmentProps, datadog: datadogProps }) => {
    if (segmentProps) {
      segment.initialize(segmentProps);
    }

    if (datadogProps) {
      datadog.initialize(datadogProps);
    }
  },
  identify: (user) => {
    if (segment.identify) {
      segment.identify(user);
    }
  },
  trackEvent: () => {
    // TODO: Add trackEvent calls for other analytics sources (Amplitude, Google Analytics, etc.) once supported by this library
  },
  trackSegmentEvent: (eventName, context) => {
    if (segment.trackEvent) {
      segment.trackEvent(eventName, context);
    }
  },
} as WhoopAnalytics<WebAnalyticsProps>;

// Type Exports
export {
  AnalyticsContext,
  UserProfile,
  InitializeFunction,
  IdentifyFunction,
  EventFunction,
  AnalyticsSource,
  WhoopAnalytics,
  WebAnalyticsProps,
  SegmentProps,
  DataDogConfig,
};
