import { Button } from '@whoop/web-components';
import CenteredCardLayout from 'common/layouts/CenteredCardLayout';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

function NotFound() {
  return (
    <>
      <h2 className={styles.title}>Page Not Found</h2>
      <br />
      <Link to="/">
        <Button variant="normal" theme="dark" data-testid="go-home-button">
          Go Home
        </Button>
      </Link>
    </>
  );
}

export default () => (
  <CenteredCardLayout>
    <NotFound />
  </CenteredCardLayout>
);
