import React, { ReactNode } from 'react';
import styles from './index.module.scss';

function LinkOut({ href, children }: { href: string; children: ReactNode }) {
  return (
    <a href={href} target="_blank" rel="noreferrer" className={styles.link}>
      {children}
    </a>
  );
}

export default LinkOut;
