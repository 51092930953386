import { Logo } from '@whoop/web-components';
import { DefaultErrorBoundary } from 'common/components/ErrorBoundary';
import React, { ReactNode } from 'react';
import styles from './index.module.scss';

interface CenteredCardLayoutProps {
  children: ReactNode;
}

function CenteredCardLayout({ children }: CenteredCardLayoutProps) {
  return (
    <div className={styles.root}>
      <div>
        <div className={styles.logoWrapper}>
          <Logo className={styles.logo} variant="trademark" />
        </div>
        <div className={styles.card}>
          <div className={styles.content}>
            <DefaultErrorBoundary>{children}</DefaultErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CenteredCardLayout;
