import auth from '../auth';
import ApiError from './ApiError';

const headers = () => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${auth.getAccessToken()}`,
});

const fetchWithErrorHandling = async (
  url: string,
  init?: RequestInit,
): Promise<Response> => {
  const response = await fetch(url, {
    ...init,
    headers: init?.headers || headers(),
  });
  if (response.status >= 400) {
    if (response.status === 401) {
      await auth.logout();
      window.location.href = '/login';
    } else {
      throw new ApiError(response);
    }
  }

  return response;
};

/**
 *
 * @param url full url to fetch
 */
const get = async <T>(
  url: string,
  config: Omit<RequestInit, 'method'> = {},
): Promise<T> => {
  const response = await fetchWithErrorHandling(url, {
    method: 'GET',
    ...config,
  });
  return response.json();
};

const getText = async (
  url: string,
  config: Omit<RequestInit, 'method'> = {},
): Promise<string> => {
  const response = await fetchWithErrorHandling(url, {
    method: 'GET',
    ...config,
  });
  return response.text();
};

/**
 *
 * @param url full url to fetch
 * @param body only json body supported
 * @returns
 */
const post = async <T>(url: string, body: any): Promise<T> => {
  const response = await fetchWithErrorHandling(url, {
    method: 'POST',
    body: JSON.stringify(body),
  });
  return response.json();
};

const emptyPost = async (url: string, body: any): Promise<void> => {
  await fetchWithErrorHandling(url, {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

const postFile = async (url: string, body: File): Promise<string> => {
  // const data = new FormData();
  // data.append('file', body);
  const response = await fetchWithErrorHandling(url, {
    method: 'POST',
    body,
  });
  return response.text();
};

const patch = async <T>(url: string, body: any): Promise<T> => {
  const response = await fetchWithErrorHandling(url, {
    method: 'PATCH',
    body: JSON.stringify(body),
  });
  return response.json();
};

const put = async <T>(url: string, body: any): Promise<T> => {
  const response = await fetchWithErrorHandling(url, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
  return response.json();
};

const doDelete = async (url: string): Promise<void> => {
  await fetchWithErrorHandling(url, {
    method: 'DELETE',
  });
};

export { ApiError };
export default {
  get,
  getText,
  post,
  postFile,
  emptyPost,
  patch,
  put,
  doDelete,
};
