import { Form, FormInput, FormInputType, useForm } from '@whoop/web-components';
import Button from 'common/components/Button';
import ErrorComponent from 'common/components/Error';
import { UnexpectedError } from 'common/errors';
import AuthenticatedLayout from 'common/layouts/AuthenticatedLayout';
import team from 'common/services/team';
import {
  AlreadyPartOfTeamError,
  TeamNameConflictError,
} from 'common/types/team';
import React from 'react';
import { useAsyncCallback } from 'react-async-hook';
import styles from './index.module.scss';

function CreateTeam() {
  const methods = useForm({
    mode: 'onChange',
  });

  const createTeamTask = useAsyncCallback(async (v) => {
    try {
      await team.createTeam(v.name);
      window.location.href = '/';
    } catch (e) {
      if (e instanceof TeamNameConflictError) {
        throw new Error('That team name is taken, choose another!');
      }
      if (e instanceof AlreadyPartOfTeamError) {
        throw new Error('You are already part of a team!');
      }
      throw new UnexpectedError();
    }
  });

  return (
    <div className={styles.root}>
      <h1>Create your team</h1>
      <Form onSubmit={(v) => createTeamTask.execute(v)} methods={methods}>
        {createTeamTask.error && (
          <ErrorComponent data-testid="form-error">
            {createTeamTask.error.message}
          </ErrorComponent>
        )}

        <div className={styles.formContents}>
          <p>
            Within your team you can invite teammates and manage your
            applications.
            <br />
            <br />
            You can change your team name at any time.
          </p>

          <FormInput
            required={{
              value: true,
              message: 'Enter the name of your new team',
            }}
            name="name"
            label="Team Name"
            // @ts-ignore
            theme="dark"
            type={FormInputType.TEXT}
            data-testid="team-name-input"
          />
        </div>

        <Button
          type="submit"
          variant="primary"
          theme="dark"
          disabled={createTeamTask.loading || !methods.formState.isValid}
          data-testid="create-team-button"
        >
          Create Team
        </Button>
      </Form>
    </div>
  );
}

export default () => (
  <AuthenticatedLayout pageIdentifier="create-team">
    <CreateTeam />
  </AuthenticatedLayout>
);
