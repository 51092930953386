import List from 'common/components/List';
import { ALL_SCOPES_INCLUDING_LEGACY } from 'common/types/apps';
import React from 'react';
import Scope from './Scope';

interface ScopesProps {
  scopes: string[];
}

/**
 * We give legacy customers continuity by displaying their legacy scopes if applicable.
 */
function Scopes({ scopes }: ScopesProps) {
  return (
    <List
      data={ALL_SCOPES_INCLUDING_LEGACY.filter((s) => scopes.includes(s.scope))}
      itemKey={(s) => s.scope}
      renderItem={(s) => (
        <Scope displayOnly scope={s.scope} description={s.description} />
      )}
    />
  );
}

export default Scopes;
