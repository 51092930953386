import Avatar from 'common/components/Avatar';
import Dropdown from 'common/components/Dropdown';
import NavLink, { NavLinkProps } from 'common/components/NavLink';
import useAuth from 'common/context/useAuth';
import useIsMobile from 'common/hooks/useIsMobile';
import React from 'react';
import { Link } from 'react-router-dom';
import HeaderMenu from './HeaderMenu';
import styles from './index.module.scss';

const NAV_LINKS: NavLinkProps[] = [
  { text: 'Apps', to: '/', testId: 'nav-link-apps' },
  { text: 'Team', to: '/team', testId: 'nav-link-teams' },
  {
    text: 'Docs',
    to: 'https://developer.whoop.com/',
    testId: 'nav-link-docs',
    target: '_blank',
    rel: 'noreferrer',
  },
];

function Header() {
  const { user } = useAuth();
  const isMobile = useIsMobile();
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Link to="/">
          <img
            className={styles.logo}
            src="/img/whoop-circle-trademark.svg"
            alt="home"
          />
        </Link>

        <div className={styles.rightCorner}>
          {!isMobile && (
            <div className={styles.desktopNavOptions}>
              {NAV_LINKS.map((navLink) => (
                <NavLink key={navLink.to} {...navLink} />
              ))}
            </div>
          )}
          <Dropdown overlay={<HeaderMenu navLinks={NAV_LINKS} />}>
            <Avatar data-testid="avatar-button" src={user?.avatarUrl} />
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

export default Header;
