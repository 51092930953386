import {
  App,
  AppDto,
  AppLimitReachedOrExceededError,
  CannotDeleteAppAsNonAdminError,
  CreateAppRequest,
  SCOPES,
  UpdateAppRequest,
} from 'common/types/apps';
import config from 'config';
import api, { ApiError } from '../api';
import webhooks from './webhooks';

function getAvailableScopesForApp(app: AppDto): string[] {
  const scopes = [...SCOPES.map((s) => s.scope)];
  const visibleLegacyScopes = ['read:team', 'read:journal'];
  visibleLegacyScopes.forEach((legacyScope) => {
    if (app.scopes.includes(legacyScope)) scopes.push(legacyScope);
  });
  return scopes;
}

const mapDto = async (appDto: AppDto): Promise<App> => {
  const webhookUrls = await webhooks.getWebhooksForClientId(appDto.client_id);
  return {
    name: appDto.name,
    clientId: appDto.client_id,
    clientSecret: appDto.client_secret,
    contacts: appDto.contacts,
    logoUrl:
      appDto.logo_url ||
      'https://app.whoop.com/assets/images/avatar-default.png',
    privacyPolicyUrl: appDto.privacy_policy_url || undefined,
    redirectUrls: appDto.redirect_urls,
    scopes: appDto.scopes,
    availableScopes: getAvailableScopesForApp(appDto),
    dayRequestLimit: appDto.day_request_limit,
    minuteRequestLimit: appDto.minute_request_limit,
    adoptedUserLimit: appDto.adopted_user_limit || undefined,
    adoptedUserCount: appDto.adopted_user_count,
    webhooks: webhookUrls,
  };
};

const getApps = async (): Promise<App[]> => {
  const apps: AppDto[] = await api.get(`${config.apiBaseUrl}/v1/app`);
  return Promise.all(apps.map(mapDto));
};

const getApp = async (id: string): Promise<App> => {
  const appDto: AppDto = await api.get(`${config.apiBaseUrl}/v1/app/${id}`);
  return mapDto(appDto);
};

const createApp = async (createAppRequest: CreateAppRequest): Promise<App> => {
  try {
    const appDto: AppDto = await api.post(`${config.apiBaseUrl}/v1/app`, {
      name: createAppRequest.name,
      contacts: createAppRequest.contacts,
      logo_url: createAppRequest.logoUrl,
      privacy_policy_url: createAppRequest.privacyPolicyUrl,
      redirect_urls: createAppRequest.redirectUrls,
      scopes: createAppRequest.scopes,
    });
    if (createAppRequest.webhookUrl) {
      await webhooks.createWebhookForClientId(
        appDto.client_id,
        createAppRequest.webhookUrl,
      );
    }
    const app = await mapDto(appDto);
    return app;
  } catch (e: any) {
    if (e instanceof ApiError && e.response.status === 400) {
      let body;
      try {
        body = await e.response.text();
      } catch (errorParsingBody) {
        throw e;
      }
      if (body.includes('limit reached or exceeded')) {
        throw new AppLimitReachedOrExceededError();
      }
    }
    throw e;
  }
};

const updateApp = async (updateAppRequest: UpdateAppRequest): Promise<App> => {
  const appDto: AppDto = await api.put(
    `${config.apiBaseUrl}/v1/app/${updateAppRequest.clientId}`,
    {
      client_id: updateAppRequest.clientId,
      name: updateAppRequest.name,
      contacts: updateAppRequest.contacts,
      logo_url: updateAppRequest.logoUrl,
      privacy_policy_url: updateAppRequest.privacyPolicyUrl,
      redirect_urls: updateAppRequest.redirectUrls,
      scopes: updateAppRequest.scopes,
    },
  );

  if (updateAppRequest.webhookUrl && !updateAppRequest.webhookId) {
    await webhooks.createWebhookForClientId(
      updateAppRequest.clientId,
      updateAppRequest.webhookUrl,
    );
  } else if (updateAppRequest.webhookUrl && updateAppRequest.webhookId) {
    await webhooks.updateWebhookByIdForClientId(
      updateAppRequest.webhookId,
      updateAppRequest.clientId,
      updateAppRequest.webhookUrl,
    );
  } else if (!updateAppRequest.webhookUrl && updateAppRequest.webhookId) {
    await webhooks.deleteWebhookById(updateAppRequest.webhookId);
  }

  return mapDto(appDto);
};

const generateLogoPresignedUrl = (): Promise<string> =>
  api.getText(`${config.apiBaseUrl}/v1/app/logo/pre-signed`);

const uploadLogo = (f: File): Promise<string> =>
  api.postFile(`${config.apiBaseUrl}/v1/app/logo`, f);

const deleteApp = async (id: string): Promise<void> => {
  try {
    const app = await getApp(id);
    await Promise.all(
      app.webhooks.map((w) => webhooks.deleteWebhookById(w.id)),
    );
    await api.doDelete(`${config.apiBaseUrl}/v1/app/${id}`);
  } catch (e: any) {
    if (e instanceof ApiError && e.response.status === 403) {
      throw new CannotDeleteAppAsNonAdminError();
    }
    throw e;
  }
};

export default {
  getApps,
  getApp,
  createApp,
  updateApp,
  generateLogoPresignedUrl,
  uploadLogo,
  deleteApp,
};
