import useAuth from 'common/context/useAuth';
import React, { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export interface BasicProps {
  children: ReactNode;
}

function RequireTerms({ children }: BasicProps) {
  const location = useLocation();
  const { user } = useAuth();

  if (!user?.hasAcceptedTerms) {
    return (
      <Navigate
        to={
          location.pathname === '/terms' || location.pathname === '/'
            ? '/terms'
            : `/terms?redir=${`${location.pathname}${
                location.search ? `${location.search}` : ''
              }`}`
        }
      />
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default RequireTerms;
