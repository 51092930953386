import { IconButton } from '@whoop/web-components';
import React, { useState } from 'react';
import styles from './index.module.scss';

interface AppSecretProps {
  secret: string;
}

function AppSecret({ secret }: AppSecretProps) {
  const [visible, setVisible] = useState(false);
  const toggleVisibility = () => setVisible(!visible);

  return (
    <div className={styles.root}>
      <pre className={styles.pre}>
        {visible ? secret : '*'.repeat(secret.length)}
      </pre>
      <IconButton
        className={styles.revealButton}
        size="small"
        name="privacy"
        onClick={toggleVisibility}
      />
    </div>
  );
}

export default AppSecret;
