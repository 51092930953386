import throttle from 'lodash.throttle';
import { useEffect, useState } from 'react';

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const getDeviceConfig = (width: number): Breakpoint => {
  if (width < 320) {
    return 'xs';
  }
  if (width < 720) {
    return 'sm';
  }
  if (width < 1024) {
    return 'md';
  }
  if (width < 1200) {
    return 'lg';
  }
  return 'xl';
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState<Breakpoint>(() =>
    getDeviceConfig(window.innerWidth),
  );

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
};

export default useBreakpoint;
