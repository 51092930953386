import { BasicComponentProps, Icon } from '@whoop/web-components';
import apps from 'common/services/apps';
import React, { useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import ErrorComponent from '../Error';
import styles from './index.module.scss';

interface ImageUploadProps extends BasicComponentProps {
  name: string;
  defaultValue?: string;
  setValue: any;
}

function ImageUpload({ defaultValue, name, setValue }: ImageUploadProps) {
  const [url, setUrl] = useState(
    defaultValue || 'https://app.whoop.com/assets/images/avatar-default.png',
  );

  const onFileChangeTask = useAsyncCallback(async (v: any) => {
    if (v.target.files && v.target.files.length > 0) {
      setUrl(URL.createObjectURL(v.target.files[0]));

      let response: any;
      try {
        response = await apps.uploadLogo(v.target.files[0]);
        setUrl(response);
        setValue(name, response);
      } catch (e) {
        throw new Error(
          'We were unable to upload your logo, please try again.',
        );
      }
    }
  });
  return (
    <div>
      <div className={styles.root}>
        <label
          htmlFor="fileInput"
          className={
            onFileChangeTask.loading ? styles.loading : styles.clickable
          }
        >
          <img className={styles.backgroundImage} src={url} alt="avatar" />
          <div className={styles.icon}>
            <Icon name="edit" />
          </div>
          <input
            id="fileInput"
            type="file"
            accept="image/png, image/jpg, image/gif, image/jpeg"
            className={styles.hidden}
            onChange={onFileChangeTask.execute}
          />
          <input type="text" name={name} className={styles.hidden} />
        </label>
      </div>
      {onFileChangeTask.error && (
        <>
          <br />
          <ErrorComponent>{onFileChangeTask.error.message}</ErrorComponent>
        </>
      )}
    </div>
  );
}

export default ImageUpload;
